<template>
  <v-card flat class="fill-height pa-2">
    <v-row no-gutters class="fill-height">
      <template v-for="(item, index) in computedInfo">
        <v-col align-self="center" class="text-truncate" :key="index" cols="12">
          <span class="font-weight-bold headers">
            {{ item.title }}
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ item.value }}</span>
                  </template>
                  <span>{{ item.value }}</span>
          </v-tooltip>
        </v-col>
      </template>
      <v-col align-self="end" cols="12">
        <v-row dense>
          <v-col>
            <held-rebid-button />
          </v-col>
          <v-col>
            <clear-call-data-button/>
          </v-col>
          <v-col>
            <incorrect-location-button/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
/* Buttons */
import HeldRebidButton from '@/app/widgets/call-information-widget/components/buttons/HeldRebidButton'
import localStorageService from '@/util/local-storage-service'
import ClearCallDataButton from '@/app/widgets/call-information-widget/components/buttons/ClearCallDataButton'
import IncorrectLocationButton from '@/app/widgets/call-information-widget/components/buttons/IncorrectLocationButton'

export default {
  name: 'CallInfoComponent',
  components: {
    HeldRebidButton,
    ClearCallDataButton,
    IncorrectLocationButton
  },
  data: () => ({
    position: null,
    dataWasSent: false,
    timeoutInterval: null
  }),
  computed: {
    ...mapGetters('adr', [
      'commentGetter',
      'deviceInfoGetter',
      'providerInfoGetter',
      'serviceInfoGetter',
      'subscriberInfoGetter'
    ]),
    ...mapGetters('held', [
      'heldGetter'
    ]),
    ...mapGetters('callInformation', [
      'dateTimeGetter',
      'esrkNumberGetter'
    ]),
    ...mapGetters('settings', ['globalSettingsGetter', 'getGlobalSetting', 'sipDefaultAddressGetter']),
    computedInfo () {
      return [
        { title: this.$t('date_time') + ':', value: this.dateTimeGetter },
        { title: this.$t('provider') + ':', value: this.providerInfoGetter.DataProviderString },
        { title: this.$t('callback_number') + ':', value: this.subscriberInfoGetter?.SubscriberData?.vcard?.tel?.uri?.replace('tel:', '') },
        { title: this.$t('esrk_number') + ':', value: this.esrkNumberGetter },
        { title: this.$t('class_of_service') + ':', value: this.serviceInfoGetter.ServiceType },
        { title: this.$t('name') + ':', value: this.heldGetter.civicAddress.NAM },
        { title: this.$t('address') + ':', value: this.getAddress() },
        { title: this.$t('city_st_zip') + ':', value: this.getCityZip() },
        { title: this.$t('lat_lon') + ':', value: this.getLatLng() },
        { title: this.$t('cell_sector_id') + ':', value: this.heldGetter.civicAddress.LOC },
        { title: this.$t('comments') + ':', value: this.commentGetter.Comment }
      ]
    },
    prepareCADData () {
      const phone = this.subscriberInfoGetter?.SubscriberData?.vcard?.tel?.uri
      const latLon = this.getLatLng()?.split(' ')

      return {
        lat: latLon[0] ? latLon[0] : '',
        lon: latLon[1] ? latLon[1] : '',
        city: this.heldGetter.civicAddress.A3,
        date: Intl.DateTimeFormat('en', { year: '2-digit', month: '2-digit' }).format(new Date(this.dateTimeGetter)),
        time: Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false, minute: '2-digit' }).format(new Date(this.dateTimeGetter)),
        street: this.heldGetter.civicAddress.STS,
        phone: phone ? phone.replace('tel:', '') : '',
        state: this.heldGetter.civicAddress.A1,
        house: this.heldGetter.civicAddress.HNO,
        houseSufix: this.heldGetter.civicAddress.HNS,
        locationInfo: this.heldGetter.civicAddress.LOC,
        prefixDirection: this.heldGetter.civicAddress.PRD
      }
    }
  },
  watch: {
    prepareCADData () {
      if (this.prepareCADData.lat && this.prepareCADData.lon && this.prepareCADData.phone) {
        this.sendCADData()

        return
      }

      if (this.timeoutInterval) clearTimeout(this.timeoutInterval)

      this.timeoutInterval = setTimeout(() => {
        if (this.dataWasSent) return

        this.$logger.debug('Sending CAD SPILL in timeout')

        this.sendCADData()
      }, 3000)
    }
  },
  methods: {
    ...mapActions('notifications', ['notificationSnackBarAction']),
    getAddress () {
      return `${this.heldGetter.civicAddress.HNO || ''} ${this.heldGetter.civicAddress.RD || ''} ${this.heldGetter.civicAddress.STS || ''}`
    },
    getCityZip () {
      return `${this.heldGetter.civicAddress.A3 || ''} ${this.heldGetter.civicAddress.A1 || ''} ${this.heldGetter.civicAddress.PC || ''}`
    },
    getLatLng () {
      return `${this.heldGetter.lat || ''} ${this.heldGetter.lng || ''}`
    },
    sendCADData (force) {
      let url = this.getGlobalSetting('cad_spill_url')
      let port = this.getGlobalSetting('cad_spill_port_number')

      this.$logger.verbose('sendCADData url:', url)
      this.$logger.verbose('sendCADData port:', port)

      if ((!port || !port.value?.current) && (!url || !url.value?.url)) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('no_cad_port_please_set')
        })

        return
      }

      url = url.value.url

      const realm = this.sipDefaultAddressGetter.realm

      port = parseInt(port.value?.current)

      this.$logger.verbose('sendCADData parsed url:', url)
      this.$logger.verbose('sendCADData parsed port:', port)

      if ((isNaN(port) || port <= 0) && !url) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('incorrect_cad_port')
        })

        return
      }

      this.$logger.debug('sendCADData position:', this.position)

      if (!this.position) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('no_cad_position_please_relogin')
        })

        return
      }

      this.$logger.debug('sendCADData if force mode:', !!force)
      this.$logger.debug('sendCADData if data was sent:', this.dataWasSent)

      if (this.dataWasSent && !force) return

      const cad = this.$CAD
        .getFormat(4)
        .clearData()
        .setPosition(this.position)
        .setData(this.prepareCADData)

      const text = cad.formatData()

      if (port > 0) {
        this.$socket.emit('send-cad-text', {
          port,
          data: text.data,
          realm
        })

        this.$logger.debug('sendCADData sent by port.')
      }

      if (url) {
        this.$socket.emit('send-cad-url', {
          url,
          data: {
            held: {
              ...this.heldGetter.civicAddress,
              NAM: this.prepareCADData.phone,
              lat: this.prepareCADData.lat,
              lng: this.prepareCADData.lon,
              street_name: this.prepareCADData.street
            },
            adr: {},
            call_type: 'BUSN',
            position_number: this.position
          }
        })

        this.$logger.debug('sendCADData sent by url.')
      }

      this.dataWasSent = true
    }
  },
  mounted () {
    this.position = localStorageService.getPositionNumber()

    this.$event.listen('incoming-ended', e => {
      this.dataWasSent = false
    })

    this.$event.listen('outgoing-ended', e => {
      this.dataWasSent = false
    })

    this.$event.listen('incoming-failed', e => {
      this.dataWasSent = false
    })

    this.$event.listen('outgoing-failed', e => {
      this.dataWasSent = false
    })

    this.$event.listen('held_was_updated', e => {
      this.sendCADData(true)
    })
  }
}
</script>

<style scoped>
  .headers{
    text-transform: uppercase;
    font-size: 0.9rem;
    color: #4ea1e4;
  }
</style>
