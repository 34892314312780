<template>

  <div v-if="dashboard">

    <golden-layout ref="goldenLayout" @state="updateLayoutEvent" @creation-error="reset" :state.sync="state"
                   class="screen" :showPopoutIcon="false" :minItemHeight="100" :minItemWidth="100">

      <gl-row :closable="false">

        <gl-col :closable="false">

          <template v-for="(widget) in dashboard.configuration.widgets">
            <gl-component @resize="onResize(widget)" :closable="true" :key="widget.id" v-model="widget.configuration"
                          @destroy="destroyWidgetEvent(widget)"
                          :title="widget.displayName">

              <component @updateConfig="updateWidgetConfigEvent(widget)" :widget="widget"
                         v-bind:is=widget.name></component>

            </gl-component>
          </template>

        </gl-col>

      </gl-row>

    </golden-layout>
  </div>

</template>

<script>

import CallControlComponent from '@/app/widgets/call-control-widget/components/CallControlComponent'
import CallHistoryTabsComponent from '@/app/widgets/call-history-tabs-widget/components/CallHistoryTabsComponent'
import EmergencyCallTabsComponent from '@/app/widgets/emergency-call-tabs-widget/components/EmergencyCallTabsComponent'
import SpeedDialTabsComponent from '@/app/widgets/speed-dial-tabs-widget/components/SpeedDialTabsComponent'
import LogoAndClockComponent from '@/app/widgets/logo-clock-widget/components/LogoAndClockComponent'
import EmergencyCallLineComponent from '@/app/widgets/emergency-call-widget/components/EmergencyCallComponent'
import AdminCallComponent from '@/app/widgets/admin-call-widget/components/AdminCallComponent'
import MapComponent from '@/app/widgets/map-widget/components/MapComponent'

/* Single widgets */
import CallInfoComponent from '@/app/widgets/call-information-widget/components/CallInfoComponent'
import HeldInformationComponent from '@/app/widgets/held-information-widget/components/HeldInformationComponent'
import AdrInformationComponent from '@/app/widgets/adr-information-widget/components/AdrInformationComponent'
import CallHistoryComponent from '@/app/widgets/call-history-widget/components/CallHistoryComponent'
import AbandonedCallsComponent from '@/app/widgets/abandoned-calls-widget/components/AbandonedCallsComponent'
import FccMasterRegistryComponent from '@/app/widgets/fcc-master-registry-widget/components/FccMasterRegistryComponent'
import SpeedDialComponent from '@/app/widgets/speed-dial-widget/components/SpeedDialComponent'
import ContactBookComponent from '@/app/widgets/contact-book-widget/components/ContactBookComponent'
import AgentsComponent from '@/app/widgets/agent-widget/components/AgentsComponent'
import LostComponent from '@/app/widgets/lost-widget/components/LostComponent'

export default {
  name: 'ChsGoldenLayout',

  components: {

    LogoAndClockComponent,
    CallControlComponent,
    AdminCallComponent,
    CallHistoryTabsComponent,
    EmergencyCallLineComponent,
    EmergencyCallTabsComponent,
    MapComponent,
    SpeedDialTabsComponent,
    CallInfoComponent,
    HeldInformationComponent,
    AdrInformationComponent,
    CallHistoryComponent,
    AbandonedCallsComponent,
    FccMasterRegistryComponent,
    SpeedDialComponent,
    ContactBookComponent,
    AgentsComponent,
    LostComponent
  },

  props: {
    dashboard: {
      type: Object
    }
  },

  data: () => ({
    state: null
  }),

  created () {
    if (this.dashboard && this.dashboard.configuration) {
      this.state = this.dashboard.configuration.layout
    }
  },

  async mounted () {
    await this.$nextTick()
    this.disableClose()

    this.$event.listen('gl_enable_edit_mode', (e) => {
      this.enableClose()
    })
    this.$event.listen('gl_disable_edit_mode', (e) => {
      this.disableClose()
    })
  },

  methods: {

    disableClose () {
      var elementPadding = document.getElementsByClassName('lm_tab')
      for (var j = 0; j < elementPadding.length; j++) {
        elementPadding[j].style.paddingRight = '10px'
      }

      var element = document.getElementsByClassName('lm_close')
      for (var i = 0; i < element.length; i++) {
        element[i].style.display = 'none'
      }

      var element2 = document.getElementsByClassName('lm_close_tab')
      for (var x = 0; x < element2.length; x++) {
        element2[x].style.display = 'none'
      }
    },

    enableClose () {
      var elementPadding = document.getElementsByClassName('lm_tab')
      for (var j = 0; j < elementPadding.length; j++) {
        elementPadding[j].style.paddingRight = '25px'
      }

      var element = document.getElementsByClassName('lm_close')
      for (var i = 0; i < element.length; i++) {
        element[i].style.display = 'block'
      }

      var element2 = document.getElementsByClassName('lm_close_tab')
      for (var x = 0; x < element2.length; x++) {
        element2[x].style.display = 'block'
      }
    },

    onResize (widget) {
      this.$event.dispatch('gl_resize', widget)
    },

    destroyWidgetEvent (widget) {
      this.$emit('destroyWidgetEvent', widget)
    },

    reset () {
      this.state = this.dashboard.configuration.layout
    },

    updateWidgetConfigEvent (widget) {
      this.$emit('updateWidgetConfigEvent', widget)
    },

    updateLayoutEvent (state) {
      if (state) {
        this.$emit('updateLayoutEvent', state)
      }
    }
  }

}
</script>

<style scoped>
.screen {
  height: 94vh;
  width: 100%;
}
</style>
