var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modal)?_c('vue-draggable-resizable',{staticClass:"chat-one",class:{
  maximized: _vm.curSize === _vm.chatSizes.EXPAND,
  minimized: _vm.curState === _vm.chatStates.MINIMIZED
},style:({
    left: _vm.left + _vm.count * 400 + 'px',
    position: _vm.config.position
  }),attrs:{"draggable":_vm.config.draggable}},[_c('div',{staticClass:"chat-one-header"},[(_vm.config.title)?_c('div',{staticClass:"chat-one-title"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"no-case ml-3 success",attrs:{"icon":"","small":"","disabled":_vm.isTDDDisabled},on:{"click":function($event){return _vm.startTTYDetection()}}},on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-deskphone")])],1)]}}],null,false,2747404317)},[_c('span',[_vm._v(_vm._s(this.$t('start_tdd')))])])],1):_vm._e(),(_vm.config.buttons.minimizeButton)?_c('div',{staticClass:"chat-one-button minimize-chat v-icon mdi",class:{
      'mdi-window-minimize': _vm.curState === _vm.chatStates.MAXIMIZED,
      'mdi-window-maximize': _vm.curState === _vm.chatStates.MINIMIZED,
    },attrs:{"title":_vm.curState === _vm.chatStates.MINIMIZED ? _vm.$t('maximize') : _vm.$t('minimize')},on:{"click":_vm.minimize}}):_vm._e(),(_vm.config.buttons.maximizeButton)?_c('div',{staticClass:"chat-one-button expand-chat v-icon mdi",class:{
      'mdi-fullscreen': _vm.curSize === _vm.chatSizes.NORMAL,
      'mdi-arrow-collapse-all': _vm.curSize === _vm.chatSizes.EXPAND
    },attrs:{"title":_vm.curSize === _vm.chatSizes.NORMAL ? _vm.$t('expand') : _vm.$t('collapse')},on:{"click":_vm.maximize}}):_vm._e(),(_vm.config.buttons.closeButton)?_c('div',{staticClass:"chat-one-button close-chat v-icon mdi mdi-close",attrs:{"title":_vm.$t('close')},on:{"click":_vm.close}}):_vm._e()]),_c('div',{staticClass:"chat-one-body"},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"chat-one-message",class:{ me: message.sender === _vm.messageTypes.own }},[(message.message.type && message.message.type === 'VIDEO')?_c('span',[_c('a',{attrs:{"target":"_blank","href":message.message.body}},[_vm._v(_vm._s(message.message.body))])]):_c('span',[_vm._v(" "+_vm._s(message.message)+" ")])])}),0),_c('div',{staticClass:"chat-one-footer"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],domProps:{"value":(_vm.message)},on:{"keyup":_vm.sendRTT,"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_c('div',[_c('div',{staticClass:"v-icon mdi mdi-send send",on:{"click":_vm.sendMessage}}),_c('div',{staticClass:"v-icon mdi mdi-video send",on:{"click":_vm.getVideoConferenceUrl}}),_c('div',{staticClass:"v-icon mdi mdi-message-text send",on:{"click":_vm.sendSMSMessage}})])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }