<template>
  <v-card flat class="fill-height pa-2 pb-0">
  <v-row dense class="fill-height full-width pl-2" v-if="$can('can_see_buttons')">

    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.ready_state_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.ready_state_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.ready_state_button.cols"
    >
      <ready-state-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.local_hold_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.local_hold_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.local_hold_button.cols">
      <local-hold-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.global_hold_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.global_hold_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.global_hold_button.cols"
    >
      <global-hold-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.mute_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.mute_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.mute_button.cols"
    >
      <mute-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.playback_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.playback_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.playback_button.cols"
    >
      <playback-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.hang_up_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.hang_up_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.hang_up_button.cols"
    >
      <hang-up-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.conference_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.conference_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.conference_button.cols"
    >
      <conference-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.transfer_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.transfer_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.transfer_button.cols"
    >
      <transfer-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.tty_rtt_text_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.tty_rtt_text_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.tty_rtt_text_button.cols"
    >
      <tty-rtt-text-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.irr_instant_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.irr_instant_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.irr_instant_button.cols"
    >
      <irr-instant-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.irr_playback_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.irr_playback_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.irr_playback_button.cols"
    >
      <irr-playback-button/>
    </v-col>
    <v-col v-show="getWidgetSettings.CallControlComponent.buttons.irr_voice_button.visible"
      :order="getWidgetSettings.CallControlComponent.buttons.irr_voice_button.order"
      :cols="getWidgetSettings.CallControlComponent.buttons.irr_voice_button.cols"
    >
      <irr-voice-button/>
    </v-col>
  </v-row>
  </v-card>
</template>

<script>
/* Buttons */
import ReadyStateButton from '@/app/widgets/call-control-widget/components/buttons/ReadyStateButton'
import LocalHoldButton from '@/app/widgets/call-control-widget/components/buttons/LocalHoldButton'
import GlobalHoldButton from '@/app/widgets/call-control-widget/components/buttons/GlobalHoldButton'
import MuteButton from '@/app/widgets/call-control-widget/components/buttons/MuteButton'
import PlaybackButton from '@/app/widgets/call-control-widget/components/buttons/PlaybackButton'
import HangUpButton from '@/app/widgets/call-control-widget/components/buttons/HangUpButton'
import ConferenceButton from '@/app/widgets/call-control-widget/components/buttons/ConferenceButton'
import TransferButton from '@/app/widgets/call-control-widget/components/buttons/TransferButton'
import TtyRttTextButton from '@/app/widgets/call-control-widget/components/buttons/TtyRttTextButton'
import IrrInstantButton from '@/app/widgets/call-control-widget/components/buttons/IrrInstantButton'
import IrrPlaybackButton from '@/app/widgets/call-control-widget/components/buttons/IrrPlaybackButton'
import IrrVoiceButton from '@/app/widgets/call-control-widget/components/buttons/IrrVoiceButton'
import { mapGetters } from 'vuex'
export default {
  name: 'CallControlComponent',
  components: {
    ReadyStateButton,
    LocalHoldButton,
    GlobalHoldButton,
    MuteButton,
    PlaybackButton,
    HangUpButton,
    ConferenceButton,
    TransferButton,
    TtyRttTextButton,
    IrrInstantButton,
    IrrPlaybackButton,
    IrrVoiceButton
  },

  computed: {
    ...mapGetters('layout', ['layoutGetter', 'getWidgetSettings'])
  },

  methods: {

  }

}
</script>

<style scoped>

</style>
