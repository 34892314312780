<template>
  <div class="pa-1s">

    <data-table-shared :headers="headers" :items="recordings" :loading="loading" :hide-footer="true">
      <template v-slot:[`item.action`]="{ item }">

        <audio-player :key="item.id" :flat="true" :auto-play="false" :item="item" />

      </template>
      <template v-slot:[`item.duration_ms`]="{ item }">
        {{ item.duration_ms | millisToMinutesAndSeconds }}
      </template>
    </data-table-shared>
  </div>
</template>

<script>
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
import AudioPlayer from '@/app/widgets/call-detail-tabs-widget/components/RecordingsAudioPlayerComponent'
import { mapActions, mapGetters } from 'vuex'
import apiService from '@/modules/api/csp'

export default {
  name: 'CallDetailsRecordingsComponent',
  components: {
    AudioPlayer,
    DataTableShared
  },
  computed: {
    ...mapGetters('callDetails', [
      'callDetailsGetter'
    ]),
    ...mapGetters('settings', ['sipDefaultAddressGetter'])
  },
  data: (vm) => ({

    audioFile: null,
    dialog: false,
    loading: false,
    note: null,
    headers: [
      {
        text: vm.$t('recording'),
        value: 'action'
      },
      {
        text: vm.$t('direction'),
        value: 'direction'
      },
      {
        text: vm.$t('duration'),
        value: 'duration_ms'
      }
    ],
    recordings: []
  }),

  watch: {
    async 'callDetailsGetter' (val) {
      if (Object.keys(val).length) {
        this.id = val
        await this.getRecordings()
      }
    }
  },

  methods: {
    ...mapActions('notifications', ['notificationSnackBarAction']),

    async getRecordings () {
      this.loading = true

      const params = {
        filter_call_id: this.callDetailsGetter.call_id,
        page_size: 1000,
        page: 1
      }

      await apiService.egress.getRecordings(this.sipDefaultAddressGetter.account_id, params).then(async (result) => {
        this.recordings = result.data.data

        this.loading = false
      }).catch((error) => {
        console.log(error)

        this.loading = false
        this.notificationSnackBarAction({
          text: 'Api Error. Recordings list not loaded.',
          color: 'error',
          show: true
        })
      })
    }

  }
}
</script>

<style scoped>

</style>
