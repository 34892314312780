<template>

    <div class="d-flex">
      <v-btn small  icon class="ma-1"  @click.native="playing ? pause() : play()" >
        <v-icon v-if="!playing || paused">mdi-play</v-icon>
        <v-icon v-else>mdi-pause</v-icon>
      </v-btn>
      <v-btn small  icon class="ma-1"  @click.native="stop()" :disabled="!loaded">
        <v-icon>mdi-stop</v-icon>
      </v-btn>
      <div class="ml-3 " v-if="mediaLoaded">
        <v-progress-linear  color="red" v-model="percentage" height="5" style="margin-top: 5px; margin-bottom: 5px; cursor: pointer" @click.native="setPosition()" :disabled="!loaded"></v-progress-linear>
        <p>{{ currentTime }} / {{ duration }}</p>
      </div>

      <audio id="player" ref="player" v-on:ended="ended" v-on:canplay="canPlay" :src="file"></audio>

    </div>

</template>
<script>
import apiService from '@/modules/api/csp'
const formatTime = second => new Date(second * 1000).toISOString().substr(11, 8)

export default {
  name: 'RecordingsAudioPlayerComponent',
  props: {
    item: {
      default: null
    },

    autoPlay: {
      type: Boolean,
      default: false
    },
    ended: {
      type: Function,
      default: () => {}
    },
    canPlay: {
      type: Function,
      default: () => {}
    }

  },
  computed: {
    duration: function () {
      return this.audio ? formatTime(this.totalDuration) : ''
    }
  },
  data () {
    return {
      mediaLoaded: false,
      file: null,
      firstPlay: true,
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: '00:00:00',
      audio: undefined,
      totalDuration: 0,
      playerVolume: 0.5
    }
  },

  methods: {

    async loadMedia (record) {
      if (record.custom_channel_vars && record.custom_channel_vars['Media-Recording-ID']) {
        await apiService.egress.getRecordedMedia(this.sipDefaultAddressGetter.account_id, record.custom_channel_vars['Media-Recording-ID']).then(response => {
          record.media_file = response.data

          const reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = async () => {
            this.file = reader.result
            this.mediaLoaded = true
            this.init()
          }
        })
      }
    },

    setPosition () {
      this.audio.currentTime = parseInt(this.audio.duration / 100 * this.percentage)
    },
    stop () {
      this.audio.pause()
      this.paused = true
      this.playing = false
      this.audio.currentTime = 0
    },
    async play () {
      if (this.playing) return

      await this.loadMedia(this.item)

      this.audio.play().then(this.playing = true)
      this.paused = false
    },
    pause () {
      this.paused = !this.paused;
      (this.paused) ? this.audio.pause() : this.audio.play()
    },

    mute () {
      this.isMuted = !this.isMuted
      this.audio.muted = this.isMuted
    },
    reload () {
      this.audio.load()
    },
    _handleLoaded: function () {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {}
            this.audio.currentTime = 0
            this.totalDuration = parseInt(this.audio.duration)
            this.loaded = true
          }
        } else {
          this.totalDuration = parseInt(this.audio.duration)
          this.loaded = true
        }

        if (this.autoPlay) this.audio.play()
      } else {
        throw new Error('Failed to load sound file')
      }
    },
    _handlePlayingUI: function (e) {
      this.audio.volume = this.playerVolume
      this.percentage = this.audio.currentTime / this.audio.duration * 100
      this.currentTime = formatTime(this.audio.currentTime)
      this.playing = true
    },
    _handlePlayPause: function (e) {
      if (e.type === 'play' && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0
        if (this.firstPlay) {
          this.firstPlay = false
        }
      }
      if (e.type === 'pause' && this.paused === false && this.playing === false) {
        this.currentTime = '00:00:00'
      }
    },
    _handleEnded () {
      this.paused = this.playing = false
    },
    init: function () {
      this.audio.addEventListener('timeupdate', this._handlePlayingUI)
      this.audio.addEventListener('loadeddata', this._handleLoaded)
      this.audio.addEventListener('pause', this._handlePlayPause)
      this.audio.addEventListener('play', this._handlePlayPause)
      this.audio.addEventListener('ended', this._handleEnded)
    }
  },
  mounted () {
    this.audio = this.$refs.player
    this.init()
  },
  beforeDestroy () {
    this.audio.removeEventListener('timeupdate', this._handlePlayingUI)
    this.audio.removeEventListener('loadeddata', this._handleLoaded)
    this.audio.removeEventListener('pause', this._handlePlayPause)
    this.audio.removeEventListener('play', this._handlePlayPause)
    this.audio.removeEventListener('ended', this._handleEnded)
  }

}
</script>
