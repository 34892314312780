import apiService from '@/modules/api/csp'
import { XMLParser } from 'fast-xml-parser'

export default function heldXmlParser () {
  return {

    locationResult: {},

    async parse (resource) {
      try {
        return await apiService.heldApiModule.get(resource).then(data => {
          return this.parseHeld(data?.data)
        })
      } catch (e) {
        console.log(e)
      }
    },

    parseHeld (data) {
      const parseOptions = { removeNSPrefix: true }
      const locationMatch = data.match(/location-info>(?=[\S\s]{10,8000})[\S\s]*location-info>/)
      const method = data.match(/method>(?=[\S\s]{10,8000})[\S\s]*method>/)

      if (locationMatch && locationMatch[0]) {
        const parser = new XMLParser(parseOptions)
        const locationInfo = parser.parse(locationMatch[0])

        this.getNestedObject(locationInfo)
        if (method[0]) {
          const locationMethodInfo = parser.parse(method[0])
          this.locationResult.method = locationMethodInfo?.['#text']?.split('>')[1]
        }
      }

      return this.locationResult
    },

    getNestedObject (locationInfo) {
      for (const key in locationInfo) {
        const val = locationInfo[key]
        if (typeof val === 'object') {
          this.getNestedObject(val)
        } else {
          if (key === 'pos') {
            this.locationResult.lat = val.split(' ')[0]
            this.locationResult.lng = val.split(' ')[1]
          } else this.locationResult[key] = val.toString()
        }
      }
    }

  }
}
