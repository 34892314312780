<template>
  <v-card flat class="fill-height" color="transparent" ref="parent-height">
      <data-table-shared :headers="commentHeaders" :items="commentItems" height="" :perPage="100" :hideFooter="true" :loading="loading.comment" />
      <data-table-shared :headers="deviceInfoHeaders" :items="deviceInfoItems" height="" :perPage="100" :hideFooter="true"  :loading="loading.deviceInfo"/>
      <data-table-shared :headers="providerInfoHeaders" :items="providerInfoItems" height="" :perPage="100" :hideFooter="true"  :loading="loading.providerInfo"/>
      <data-table-shared :headers="serviceInfoHeaders" :items="serviceInfoItems" height="" :perPage="100" :hideFooter="true" :loading="loading.serviceInfo" />
      <data-table-shared :headers="subscriberInfoHeaders" :items="subscriberInfoItems" height="" :perPage="100" :hideFooter="true" :loading="loading.subscriberInfo" />
  </v-card>
</template>

<script>
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
/* Mixins */
import RearrangeNestedObject from '@/mixins/rearrange-nested-object.mixin'
/* Parser */
import adrXmlParser from '@/modules/api/csp/parsers/adr/adr-xml.parser'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'CallDetailsAdrComponent',
  mixins: [RearrangeNestedObject],
  components: {
    DataTableShared
  },
  data: (vm) => ({
    loading: {
      comment: false,
      serviceInfo: false,
      deviceInfo: false,
      providerInfo: false,
      subscriberInfo: false
    },
    commentHeaders: [
      { text: vm.$t('comment_info'), value: 'name', width: '40%' },
      { text: vm.$t('details'), value: 'value', width: '60%' }
    ],
    deviceInfoHeaders: [
      { text: vm.$t('device_info'), value: 'name', width: '40%' },
      { text: vm.$t('details'), value: 'value', width: '60%' }
    ],
    providerInfoHeaders: [
      { text: vm.$t('provider_info'), value: 'name', width: '40%' },
      { text: vm.$t('details'), value: 'value', width: '60%' }
    ],
    serviceInfoHeaders: [
      { text: vm.$t('service_info'), value: 'name', width: '40%' },
      { text: vm.$t('details'), value: 'value', width: '60%' }
    ],
    subscriberInfoHeaders: [
      { text: vm.$t('subscriber_info'), value: 'name', width: '40%' },
      { text: vm.$t('details'), value: 'value', width: '60%' }
    ],
    commentItems: [],
    deviceInfoItems: [],
    providerInfoItems: [],
    serviceInfoItems: [],
    subscriberInfoItems: []
  }),
  computed: {
    ...mapGetters('callDetails', [
      'callDetailsGetter'
    ])
  },
  watch: {
    async 'callDetailsGetter' (val) {
      await this.getAdrInformation(val)
    }
  },
  methods: {
    ...mapMutations('callDetails', [
      'setCallDetailsAdrMutator'
    ]),
    async getAdrInformation (callDetails) {
      if (Object.keys(callDetails).length) {
        this.loading.comment = true
        this.loading.serviceInfo = true
        this.loading.deviceInfo = true
        this.loading.providerInfo = true
        this.loading.subscriberInfo = true

        await adrXmlParser().parse(callDetails.subscriber_info_url, 'SubscriberInfo').then(data => {
          this.getNestedObject(data, 'subscriberInfoItems', false)
          this.setCallDetailsAdrMutator({ value: data, type: 'subscriberInfo' })
        }).finally(() => { this.loading.subscriberInfo = false })

        await adrXmlParser().parse(callDetails.comment_url, 'Comment').then(data => {
          this.getNestedObject(data, 'commentItems', false)
          this.setCallDetailsAdrMutator({ value: data, type: 'comment' })
        }).finally(() => { this.loading.comment = false })

        await adrXmlParser().parse(callDetails.service_info_url, 'ServiceInfo').then(data => {
          this.getNestedObject(data, 'serviceInfoItems', false)
          this.setCallDetailsAdrMutator({ value: data, type: 'serviceInfo' })
        }).finally(() => { this.loading.serviceInfo = false })

        await adrXmlParser().parse(callDetails.device_info_url, 'DeviceInfo').then(data => {
          this.getNestedObject(data, 'deviceInfoItems', false)
          this.setCallDetailsAdrMutator({ value: data, type: 'deviceInfo' })
        }).finally(() => { this.loading.deviceInfo = false })

        await adrXmlParser().parse(callDetails.provider_info_url, 'ProviderInfo').then(data => {
          this.getNestedObject(data, 'providerInfoItems', false)
          this.setCallDetailsAdrMutator({ value: data, type: 'providerInfo' })
        }).finally(() => { this.loading.providerInfo = false })
      } else {
        this.commentItems = []
        this.deviceInfoItems = []
        this.providerInfoItems = []
        this.serviceInfoItems = []
        this.subscriberInfoItems = []
      }
    }
  }
}
</script>

<style>
</style>
