<template>
  <v-data-table
    dense
    ref="parent-height"
    cypress="agents_table"
    class="px-4 py-2"
    :dark="$vuetify.theme.dark"
    :items="sortedItems"
    :height="fixedHeight - 80"
    item-key="id"
    :loading=loading
    :headers="headers"
    :loading-text="$t('loading')"
    selectable-key="id"
    :items-per-page="itemPerPage"
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span :title="item.name"
                v-bind="attrs"
                v-on="on">
            {{ item.name }}
          </span>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.username`]="{ item }">
      <v-icon color="green" v-if="item.state == 'READY'">mdi-checkbox-marked-circle</v-icon>
      <v-icon color="orange" v-if="item.state == 'ON_CALL'">mdi-phone</v-icon>
      <v-badge
        color="error"
        overlap
        top
        right
        icon="mdi-heart"
        v-if="item.state == 'ON_EMERGENCY_CALL'"
      >
        <v-icon color="orange">mdi-phone</v-icon>
      </v-badge>
      <v-icon color="red" v-if="!item.state || item.state == 'NOT_READY'">mdi-cancel</v-icon>
    </template>

    <template v-slot:[`item.dial`]="{ item }">
      <call-back-button :dialNumber="`sip:${item.sip_address}`"/>
      <call-transfer-button :dialNumber="`sip:${item.sip_address}`"/>
      <observe-button :agent="{...item}" :dialNumber="`sip:${item.sip_address}`"/>
      <whisper-button :agent="{...item}" :dialNumber="`sip:${item.sip_address}`"/>
      <barge-button :agent="{...item}" :dialNumber="`sip:${item.sip_address}`"/>
    </template>
  </v-data-table>
</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'

/* Shared */
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallTransferButton from '@/app/shared/buttons/CallTransferButton'
import ObserveButton from '@/app/shared/buttons/ObserveButton'
import WhisperButton from '@/app/shared/buttons/WhisperButton'
import BargeButton from '@/app/shared/buttons/BargeButton'

import { mapGetters, mapActions } from 'vuex'

/* Helpers */
import textHelper from '@/helpers/text.helper'
export default {
  name: 'AgentsComponent',
  mixins: [parentHeight],
  components: {
    CallBackButton,
    CallTransferButton,
    ObserveButton,
    WhisperButton,
    BargeButton
  },
  data: (vm) => ({
    loading: false,
    itemPerPage: 10,
    headers: [
      { text: vm.$t('full_name'), value: 'name', sortable: false },
      { text: vm.$t('status'), value: 'username', sortable: false },
      { text: vm.$t('dial'), value: 'dial', sortable: false }
    ],
    items: {
      value: []
    },
    sortedItems: []
  }),
  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter']),
    ...mapGetters('callAgents', ['callAgentsGetter'])
  },
  watch: {
    'sipDefaultAddressGetter.account_id' (val) {
      this.getUsers(val)
    },
    'sipDefaultAddressGetter' () {
      this.prepareAgents()
    },
    'items.value' () {
      this.prepareAgents()
    }
  },
  methods: {
    ...mapActions('callAgents', ['callAgentsAction']),
    prepareAgents () {
      this.sortAgents()

      if (!this.sipDefaultAddressGetter) return

      this.$socket.emit('join-realm', {
        realm: this.sipDefaultAddressGetter.realm,
        userID: this.sipDefaultAddressGetter.sip_address
      })
    },
    sortAgents () {
      if (!this.items || !this.items.value) return

      const val = this.items.value

      if (!val || !this.sipDefaultAddressGetter) return

      this.sortedItems = [...val]

      val.forEach(item => {
        item.sip_address = `${item.username.toLowerCase()}@${this.sipDefaultAddressGetter.realm}`

        this.$socket.on(item.sip_address, (data) => {
          item.state = data.state

          const sortedItems = [...val]

          const stateValues = {
            ON_EMERGENCY_CALL: 0,
            ON_CALL: 1,
            READY: 2,
            NOT_READY: 3,
            undefined: 4
          }

          sortedItems.sort(function (a, b) {
            return stateValues[a.state] - stateValues[b.state]
          })

          this.sortedItems = sortedItems
          this.$forceUpdate()
        })
      })
    },
    async getUsers (accountId) {
      this.loading = true
      await this.callAgentsAction({
        accountId: accountId,
        params: {}
      }).catch(() => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },
    mergeAndSubstring (name, lastname) {
      const agent = `${name} ${lastname}`
      return textHelper.substringIf(agent, 0, 25, '...')
    }
  },
  async mounted () {
    this.items = await this.$localCacher.indexDB.agents.get()

    this.$event.listen('gl_resize', (e) => {
      this.modifyHeight()
    })
  }
}
</script>

<style scoped>

</style>
