<template>
  <data-table-shared :headers="headers" :loading="loading" :items="computedInfo" height="" :perPage="100" :hide-footer="true" />
</template>

<script>
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
/* Helpers */
import timeHelper from '@/helpers/time.helper'
import { mapGetters } from 'vuex'
export default {
  name: 'CallDetailsInformationComponent',
  components: {
    DataTableShared
  },
  data: (vm) => ({
    loading: false,
    headers: [
      { text: vm.$t('title'), value: 'title' },
      { text: vm.$t('details'), value: 'value' }
    ]
  }),
  computed: {
    ...mapGetters('callDetails', [
      'callDetailsHeldGetter',
      'callDetailsAdrGetter',
      'callDetailsGetter'
    ]),
    computedInfo () {
      return [
        { title: this.$t('date_time') + ':', value: (this.callDetailsGetter.created_at) ? timeHelper.dateFormatter(this.callDetailsGetter.created_at) : '' },
        { title: this.$t('provider') + ':', value: this.callDetailsAdrGetter?.providerInfo?.DataProviderString },
        { title: this.$t('callback_number') + ':', value: this.callDetailsAdrGetter?.subscriberInfo?.SubscriberData?.vcard?.tel?.uri },
        { title: this.$t('esrk_number') + ':', value: null },
        { title: this.$t('class_of_service') + ':', value: this.callDetailsAdrGetter?.serviceInfo?.ServiceType },
        { title: this.$t('name') + ':', value: null },
        { title: this.$t('address') + ':', value: this.getAddress() },
        { title: this.$t('city_st_zip') + ':', value: this.getCityZip() },
        { title: this.$t('lat_lon') + ':', value: this.getLatLng() },
        { title: this.$t('cell_sector_id') + ':', value: null },
        { title: this.$t('comments') + ':', value: this.callDetailsAdrGetter?.comment?.Comment }
      ]
    }
  },
  methods: {
    getAddress () {
      return `${this.callDetailsHeldGetter?.HNO || ''} ${this.callDetailsHeldGetter?.RD || ''} ${this.callDetailsHeldGetter?.STS || ''}`
    },
    getCityZip () {
      return `${this.callDetailsHeldGetter?.A3 || ''} ${this.callDetailsHeldGetter?.A1 || ''} ${this.callDetailsHeldGetter?.PC || ''}`
    },
    getLatLng () {
      return `${this.callDetailsHeldGetter?.lat || ''} ${this.callDetailsHeldGetter?.lng || ''}`
    }
  }
}
</script>

<style scoped>

</style>
