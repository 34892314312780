<template>
  <v-btn
      @click="localHold()"
      class="text-icon-button"
      :color="callGetter.localHold ? 'primary' : 'grey'"
      large
      dark
      width="100%"
      height="100%"
      :disabled="!callGetter.canLocalHold"
      id="action_KeyP"
  >
    <v-icon> {{ callGetter.localHold ? 'mdi-play' : 'mdi-pause' }}</v-icon>
    <div class="font-weight-bold pt-2">{{  callGetter.localHold ? $t('unhold') : $t('hold') }}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import localHoldMixin from '@/mixins/sip/local-hold.mixin'

export default {
  name: 'LocalHoldButton',
  mixins: [localHoldMixin],
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  }
}
</script>

<style scoped>

</style>
