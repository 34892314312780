<template>
  <v-btn
    height="100%"
    class="v-btn-justify-start call_list no-transform"
    block
    :color="color"
    dark
    :disabled="!active"
    @click="handleCall()"
    v-if="$can(`can_see_${type}_calls`)"
  >
    <v-icon
      left
      dark
    >
      {{ icon }}
    </v-icon>
    <template class="template">
      <span>
        {{ answered ? timerText : `L${number}` }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs"
                  v-on="on"
                  class="mt-4 mx-0">
              {{ address | substringIf(0,20,'...') }}
            </span>
          </template>
          <span>{{ address }}</span>
        </v-tooltip>
      </span>
    </template>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* Mixins */
import answerMixin from '@/mixins/sip/answer.mixin'
import timerMixin from '@/mixins/timer.mixin'
import adrMixin from '@/mixins/sip/adr.mixin'
import heldMixin from '@/mixins/sip/held.mixin'
import esrkMixin from '@/mixins/sip/esrk.mixin'

export default {
  name: 'CallLineButton',
  mixins: [answerMixin, timerMixin, adrMixin, heldMixin, esrkMixin],
  props: ['index', 'number', 'type'],
  data: () => ({
    active: false,
    answered: false,
    session: null,
    color: 'error',
    interval: null,
    icon: 'mdi-phone-hangup',
    adr: null,
    held: null,
    address: ''
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    ...mapGetters('callLines', [
      'customCallLinesGetter'
    ])
  },
  watch: {
    active (val) {
      if (val) {
        this.icon = 'mdi-phone-ring'
        this.interval = setInterval(() => {
          this.blinking()
        }, 500)
      } else {
        this.icon = 'mdi-phone-hangup'
        this.answered = false
        clearInterval(this.interval)
        this.color = 'error'
        this.address = ''
      }
    },
    answered (val) {
      if (val) {
        this.startTimer()
        this.getEsrkNumber(this.session)
        this.populateAdr(this.adr)
        this.populateHeld()
      } else {
        this.stopTimer()
      }
    },
    async session (val) {
      if (!val) return
      if (this.type === 'admin') {
        const headers = val._request?.headers

        if (headers) {
          for (const key in this.customCallLinesGetter) {
            if (!headers[key]) continue

            headers[key].forEach(item => {
              if (item.raw === this.customCallLinesGetter[key].value) {
                this.address = this.customCallLinesGetter[key].name
              }
            })
          }
        }
      }

      if (this.$can('can_get_preflight_location')) {
        await this.getHeld(val, false, false).then(data => {
          this.held = data
          if (this.session.status === 4) {
            const number = data?.held?.HNO || ''
            const address = data?.held?.RD || ''
            const sts = data?.held?.STS || ''
            this.address = this.type !== 'admin' ? `${number} ${address} ${sts}` : this.address
          }
        })
        await this.getAdr(val).then(data => {
          this.adr = data
        })
      }
    }
  },
  methods: {
    ...mapMutations('adr', [
      'commentMutator',
      'deviceInfoMutator',
      'providerInfoMutator',
      'serviceInfoMutator',
      'subscriberInfoMutator'
    ]),
    ...mapMutations('held', [
      'heldMutator'
    ]),
    blinking () {
      if (this.color === 'error') {
        this.color = 'grey'
      } else {
        this.color = 'error'
      }
    },
    handleCall () {
      if (!this.$sip.canAnswer) return
      setTimeout(() => {
        this.$sip.canAnswer = true
      }, 2000)
      if (this.session?.isOnHold()?.local) {
        this.unHoldCall()
      } else {
        this.answerCall()
      }
    },
    answerCall () {
      if (!this.callGetter.hasActiveCall && this.$sip.canAnswer) {
        this.$sip.canAnswer = false
        this.answered = true
        this.answer(this.session)
        this.address = ''
        clearInterval(this.interval)
        this.color = 'success'
        this.icon = 'mdi-phone-in-talk'
      } else return false
    },
    unHoldCall () {
      if (!this.callGetter.hasActiveCall && this.$sip.canAnswer) {
        this.$sip.canAnswer = false
        this.populateAdr()
        this.populateHeld()
        this.icon = 'mdi-phone-in-talk'
        this.color = 'success'
        this.session.unhold()
        const event = new Event('incoming-confirmed')
        event.session = this.session
        event.sessionId = this.session.id

        document.dispatchEvent(event)
      }
    },
    onHoldBtnStatus () {
      if (this.session?.isOnHold()?.local) {
        this.icon = 'mdi-play'
        this.color = 'blue'
      }
    },
    async populateAdr (adr) {
      if (adr) {
        await this.clearAdr()
        setTimeout(async () => {
          await this.commentMutator(this.adr?.comment)
          await this.deviceInfoMutator(this.adr?.deviceInfo)
          await this.providerInfoMutator(this.adr?.providerInfo)
          await this.serviceInfoMutator(this.adr?.serviceInfo)
          await this.subscriberInfoMutator(this.adr?.subscriberInfo)
        }, 500)
      } else await this.getAdr(this.session, true)
    },
    async populateHeld () {
      await this.getHeld(this.session, true)
    }
  },
  mounted () {
    this.$event.listen('on-hold-call', async e => {
      this.onHoldBtnStatus()
    })
  }
}
</script>

<style scoped>
.no-transform{
  text-transform: none !important;
}
</style>
