import store from '@/store'
import callMixin from '@/mixins/sip/call.mixin'
import warmTransferMixin from '@/mixins/sip/warm-transfer.mixin'
export default {
  mixins: [callMixin, warmTransferMixin],
  methods: {
    callOrTransfer (target) {
      if (!store.getters['global/callGetter'].hasActiveCall) {
        this.call(target)
      } else {
        this.warmTransfer(target)
      }
    }
  }
}
