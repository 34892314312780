<template>
  <v-btn
      class="pa-2"
      dark
      block
      height="100%"
      color="primary"
      @click="clearCallInformation"
      :disabled="callGetter.hasActiveCall"
      :loading="loading"
  >
    <v-icon color="white">mdi-close-circle-outline</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* Mixins */
import adrMixin from '@/mixins/sip/adr.mixin'
import heldMixin from '@/mixins/sip/held.mixin'

export default {
  name: 'ClearCallDataButton',
  mixins: [adrMixin, heldMixin],
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  },
  methods: {
    ...mapMutations('callInformation', [
      'esrkNumberMutator',
      'dateTimeMutator'
    ]),
    async clearCallInformation () {
      this.loading = true
      setTimeout(async () => {
        this.esrkNumberMutator(null)
        this.dateTimeMutator(null)
        await this.clearAdr()
        await this.clearHeld().finally(() => {
          this.loading = false
        })
      }, 500)
    }
  }
}
</script>

<style scoped>

</style>
