<template>
  <v-data-table
    class="px-4 py-2"
    :headers="headers"
    :items="(items && items.value) || []"
    item-key="id"
    selectable-key="id"
    single-select
    dense
    :dark="$vuetify.theme.dark"
    :height="fixedHeight - 150"
    ref="parent-height"
    :loading="loading"
    :loading-text="$t('loading')"
    :footer-props="{itemsPerPageOptions : [5,10,15]}"
    @update:options="paginationData"
    :server-items-length="(totalItems && totalItems.value) || 0"
  >

    <template v-slot:[`top`]>

      <v-text-field :disabled="loading"
                    v-model="search" clearable @click:clear="clearSearch"
                    :label="$t('search')"
      >
        <v-btn slot="append-outer" color="primary" elevation="0" :disabled="loading">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

      </v-text-field>
    </template>

    <template v-slot:[`item.from`]="{ item }">
      {{ item.call_from }}
    </template>

    <template v-slot:[`item.datetime`]="{ item }">
      {{ item.created_at | dateFormatter }}
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <call-back-button :dialNumber="item.call_from"/>
      <call-resolve-button :data="item" />
      <call-detail-button :data="item" reason="abandoned"/>
    </template>

  </v-data-table>
</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
/* Buttons */
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallDetailButton from '@/app/shared/buttons/CallDetailButton'
import CallResolveButton from '@/app/shared/buttons/CallResolveButton'

/* Vuex */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AbandonedCallsComponent',
  mixins: [parentHeight],
  props: ['parentElement'],
  components: {
    CallBackButton,
    CallResolveButton,
    CallDetailButton
  },
  data: (vm) => ({
    search: '',
    loading: false,
    headers: [
      { text: vm.$t('from'), value: 'from', sortable: false },
      { text: vm.$t('date'), value: 'datetime', sortable: false },
      { text: vm.$t('action'), value: 'action', sortable: false }
    ],
    totalItems: 0,
    items: [],
    options: {
      itemsPerPage: 15
    }
  }),
  computed: {
    ...mapGetters('abandonedCalls', ['getAbandonedGetter'])
  },
  methods: {
    ...mapActions('abandonedCalls', ['getUnresolvedAbandonedCallsAction', 'setUnresolvedAbandonedCallsCountAction']),
    clearSearch () {
      this.search = ''
    },
    async paginationData (data) {
      this.items = await this.$localCacher.indexDB.abandonedCalls.get({
        offset: (data.page - 1) * data.itemsPerPage,
        limit: data.itemsPerPage,
        call_from: data.search
      })
    }
  },
  watch: {
    async search () {
      this.selected = []

      this.items = await this.$localCacher.indexDB.abandonedCalls.get({
        limit: 10,
        call_from: this.search
      })

      this.totalItems = await this.$localCacher.indexDB.abandonedCalls.count({
        call_from: this.search
      })
    },
    'totalItems.value' () {
      this.setUnresolvedAbandonedCallsCountAction(this.totalItems.value || 0)
    }
  },
  async mounted () {
    this.loading = true

    this.items = await this.$localCacher.indexDB.abandonedCalls.get({
      limit: 10
    }).finally(() => {
      this.loading = false
    })

    this.totalItems = await this.$localCacher.indexDB.abandonedCalls.count()

    this.$event.listen('gl_resize', (e) => {
      this.modifyHeight()
    })
  }
}
</script>

<style scoped>

</style>
