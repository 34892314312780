import store from '@/store'
import sipHelper from '@/helpers/sip.helper'
export default {
  methods: {
    call (target, extraHeaders = [], eventHandlers = {}) {
      if (!store.getters['global/callGetter'].hasActiveCall) {
        this.$sip.call(sipHelper.getTarget(target, this.$sip.domain), sipHelper.getOptions(extraHeaders, eventHandlers))
      }
    }
  }
}
