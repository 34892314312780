import { render, staticRenderFns } from "./BargeButton.vue?vue&type=template&id=e96b1c4e&scoped=true&"
import script from "./BargeButton.vue?vue&type=script&lang=js&"
export * from "./BargeButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e96b1c4e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VTooltip})
