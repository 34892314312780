import { mapGetters } from 'vuex'

export default {
  mixins: [],
  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter']),
    ...mapGetters('global', ['callGetter'])
  },
  methods: {

    setReadyState (readyState = 'NOT_READY') {
      console.log('this.sipDefaultAddressGetter', this.sipDefaultAddressGetter)
      if (this.sipDefaultAddressGetter && this.sipDefaultAddressGetter.id) {
        this.$socket.emit('change-ready-state', {
          realm: this.sipDefaultAddressGetter.realm,
          state: readyState,
          userID: this.sipDefaultAddressGetter.sip_address
        })

        console.info('set ready state : ' + readyState)
      }
    },

    setNotReadyState () {
      this.setReadyState('NOT_READY')
    },
    setReadySate () {
      this.setReadyState('READY')
    },
    setOnCallState () {
      const callType = this.callGetter.isEmergency ? 'ON_EMERGENCY_CALL' : 'ON_CALL'
      this.setReadyState(callType)
    }

  }
}
